import findpower from './assets/findpower.jpg';
import './Hero.css';

const Hero = () => {
  return (
    <div className="hero-container">
      <div className="image-container" style={{ backgroundImage: `url(${findpower})` }}></div>
      <div className="text-container  ">
        <h1 style={{
          paddingTop: '0px',
          marginTop: '0px',
          fontSize: '1em',
          color: '#d9b99b',
          textAlign: 'center',
          margin: '20px',
          justifyContent: 'center',
        }}>
          <div className='w-full  font-bold text-[#d9b99b]flex-col items-center justify-center mx-auto '>
            <span className="text-3xl md:text-4xl" >life</span>
            <span className="text-4xl md:text-7xl">BALANZ</span>
            <span className="text-3xl md:text-4xl" >coach</span>
          </div>
        </h1>
        <h1 className="text-center text-gray-500 text-2xl md:text-4xl" 
        style={{margin: '20px',}}
          // 
        >
          Fátima Sheikh
        </h1>
      </div>
    </div>
  );
};

export default Hero;