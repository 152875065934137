// import React from "react";
import React, { useEffect, useRef } from 'react';
import "./Analytics.css";


import model from "./assets/fatima-1.jpg";
import aboutme from "./assets/fatima-9.jpg";
import lifeseal from "./assets/lifecoach.png";
import healthseal from "./assets/healthcoach.png";
import { Navigate, useNavigate } from "react-router-dom";
const Analytics = ({ onGetStartedClick }) => {
  const navigate = useNavigate();
  const imgRef1 = useRef();
const imgRef2 = useRef();
useEffect(() => {
  if (imgRef1.current && imgRef2.current) {
    imgRef1.current.style.width = `${imgRef1.current.naturalWidth /1.5}px`;
    imgRef1.current.style.height = `${imgRef1.current.naturalHeight /1.5 }px`;
    imgRef2.current.style.width = `${imgRef2.current.naturalWidth /1.5}px`;
    imgRef2.current.style.height = `${imgRef2.current.naturalHeight / 1.5}px`;
  }
}, []);
  return (
    <div >
    <div className="flex flex-row-reverse">
  {/* <img className="w-[500px] mx-auto my-4" src={model} alt="/" style={{  margin: "20px" }} /> */}
  <div  className="w-full bg-white py-12 px-4"  >
      
      <div className="max-w-[1240px] mx-auto grid md:grid-cols-1 flex flex-col justify-center items-center mb-6  "style={{paddingLeft: "0vw"}}>
        
        {/* <div className="flex flex-col justify-center"></div> */}
  <p className="font-thin text-gray-1000" >
          {/* <div
        id=""
        className=" font-semi-bold mb-6 md: text-center h-12 text-4xl font-semi-bold font-serif"
      >
        Life Balance coach
        <div className="ml-auto mr-auto mt-1 w-1/5 border-b border-double border-gray-400"></div> */}
        {/* </div> */}
          When the world we live in demands so much of us, leaving us mentally, emotionally and physically depleted, finding the right balance is a necessity not a luxury.
          <br></br>
          <br></br>

Busy has become a badge we wear with honor, taking pride in always being occupied. The consequences? Our mental health suffers. Our physical health suffers. Stress manifests itself in hidden ways, affecting the quality of our lives, limiting us from living our fullest. Sound familiar?
<br></br>
<br></br>
For most of us it is all or nothing, the approach we have been programmed with, limiting us in exploring different methods to reach our goals. We wait for when we have the time to do it all and do it right, and when that time is not available, we end up doing nothing. We wait and wait for that perfect moment to start the perfect journey to get the perfect results. And when it doesn’t happen, we give up- only to blame ourselves for the poor results.
<br></br>

<br></br>
I know it all too well. My own life experiences reflect the “busy” lifestyle. As a wife and a mother of two, working, starting my own business and volunteering in the community, how have I found my balance? When and how do I find the time to take care of myself?
<br></br>
<br></br>
I invite you to come explore with me, a new and fresh perspective on your lifestyle and how you can achieve an equilibrium, balance your priorities and live your best life, while achieving your dreams. Let me coach you and help you implement small habit changes- making the smallest shifts in your daily life to reap the biggest benefits. 
<br></br>
<br></br>
After all, the smallest hinge opens the biggest door.
<br></br>
<br></br>
Step away from this pattern, join me and learn how the smallest change can have the biggest impact and make a big difference! Implement small daily habit changes and be amazed at the results that follow.
<br></br>
<br></br>

          </p >
      
          <div style={{ display: 'flex', justifyContent: 'center' }}>
  <button
    onClick={() => {
      navigate("./form");
    }}
    className="bg-gray-600 text-[#d9b99b] w-full sm:w-1/3 md:w-1/4 lg:w-1/5 xl:w-1/6 rounded-md font-medium py-3 justify-center hover:bg-gray-900 transition duration-200 ease-in-out"
    style={{ marginTop: "10px", padding: "10px 20px", fontSize: "18px" }}
  >
    Let's get started
  </button>
</div>
    </div>
    
          
        </div>
        
      </div><div

        id="about"
        className=" font-semi-bold mb-6 md: text-center h-12 text-4xl font-semi-bold font-serif font-thin text-gray-1000"

      >
        About Me
        <div className="ml-auto mr-auto mt-1 w-1/5 border-b border-double border-gray-400"></div>
        </div>
      
      <div className="flex flex-row-reverse line-height: 0">
  {/* <img className="w-[500px] mx-auto my-4" src={model} alt="/" style={{  margin: "20px" }} /> */}
  <div  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' , marginTop:'0px'}} className="w-full bg-white  px-4 line-height: 0 "  >
    
      
      <div className="max-w-[1240px] mx-auto grid md:grid-cols-1 flex flex-col justify-center items-center mb-6   "style={{paddingLeft: "0vw", marginTop:'0px',}}>
        
        
        {/* <div className="flex flex-col justify-center"></div> */}
        <div className="container line-height: 0">
        <div className="aboutmeimage-mobile">
    <img className="w-[500px] mx-auto my-4 aboutmeimage" src={aboutme} alt="About me" style={{ marginRight: "20px" , marginTop: "0px"}} />
  </div>
  <p className="font-thin text-gray-1000" >
    
    Coaching has been a lifelong passion of mine. Throughout my professional career, I have had the opportunity to coach at different capacities, and now have the privilege to coach on a personal level. Certified as a health and life coach, my approach is to implement small habit changes in your daily life to help you achieve success, while maintaining a healthy balance.
    <br></br>
    <br></br>
    My own life journey has taken me from working in the corporate world, to a stay at home mom and then re-entry into the professional workspace. These transitions have been life changing and a source of learning for my own transformation.
    <br></br>
    <br></br>
    In the early stages of my professional career, I transitioned from engineering into sales and marketing. I worked in several industries ranging from hydroelectric and petroleum gas detection to advertising and healthcare before stepping into the role of a full time mom (by far the hardest and most fulfilling!) 
    <br></br>
    <br></br>
    Community outreach has always been a big focus of mine. I was heavily involved in my children’s school PTA and Sunday School, taking on roles from President to V.P. of events- and pretty much any role you can think of in between! I continue to volunteer in any capacity, as being part of a larger community and giving back is an important part of my belief system.
    
  
    <br></br>
    <br></br>
  Degrees and Certification:
  <br></br>
  Health & Life Coaching Certification from HCI
  <br></br>
M.B.A. in Marketing from Loyola University
<br></br>
B.S. in Mechanical Engineering from RIT
  <br></br>
  
  <div style={{ display: 'flex'}}>
  <div className="aboutmeimage-desktop" style={{display:"flex"}}>
  <img ref={imgRef1} src={lifeseal}style={{ transform:'scale(1)', marginTop:'10px', width:"167px", height:'163px'}} ></img>
  <img ref={imgRef2} src={healthseal} style={{ transform:'scale(1)', marginTop:"10px",width:"167px", height:'163px'}}></img>
  </div>
  
  </div>
  <br></br>
  <a href="https://www.linkedin.com/in/fatima-sheikh-ba85834" className="text-blue-500"target="_blank" rel="noopener noreferrer">Linkedin Profile</a>
    <br></br>
    <a href="https://www.instagram.com/lifebalanzcoach" className="text-blue-500"target="_blank" rel="noopener noreferrer">Instagram: lifebalanzcoach</a>
    <br></br>
    <a href="https://www.facebook.com/lifebalanzcoach"className="text-blue-500" target="_blank" rel="noopener noreferrer">Facebook: lifebalanzcoach</a>
</p>
<div className="aboutmeimage-desktop">
    <img className="w-[500px] mx-auto my-4 aboutmeimage" src={aboutme} alt="About me" style={{ margin: "20px" }} />
  </div>

  {/* Mobile version */}
  

</div>

</div>



  
    {/* <img src={aboutme} alt="About me" style={{ maxWidth: '500px', maxHeight: '500px' }} /> */}
 
    
    
 
  
</div>
    </div>
    </div>
  );
};

export default Analytics;
