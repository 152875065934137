import React from "react";
import abigal from "./assets/abigail.png";
import zarina from "./assets/zarina.png";
import lizjosef from "./assets/lizjosef.png";

const Testimonials = () => {
  return (
    <div>
      <div
      
        id="about"
        className=" font-semi-bold mb-6 md: text-center h-12 text-4xl font-semi-bold font-serif font-thin text-gray-1000"
      >
        <br></br>
        <br></br>
        Testimonials
        <div className="ml-auto mr-auto mt-1 w-1/5 border-b border-double border-gray-400"></div>
        
      </div>
      <section>
        <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8 " id="cards">
          <div className="[column-fill:_balance] sm:columns-3 sm:gap-6 lg:columns-3 lg:gap-8">
          <div className="mb-8 sm:break-inside-avoid">
              <blockquote className="rounded-xl bg-gray-50 p-6 shadow">
              <div className="mt-4 flex items-center gap-4">
                <img
                  alt="Liz Josefsberg"
                  src={lizjosef}
                  className="h-12 w-12 rounded-full object-cover"
                />

                <div className="text-sm">
                  <p className="font-medium">Liz Josefsberg - Celebrity Weight Loss Expert</p>
                 
                </div>
              </div> 
                <p className="leading-relaxed text-gray-700">
                It’s hard to put into words how truly magical Fatima is. I will start with the fact that she is one of the smartest and most capable people I have ever had the chance to work with as a coach. Her intelligence is only exceeded by her unique depth of intuition.  The combo of smarts and intuition, alongside her direct, no-nonsense approach, makes Fatima a powerhouse of a coach. She is laser-focused on finding solutions that work. I have been honored to have her working alongside me in my weight loss business for over 2 years. She is not only a coach, but has trained all of our coaches as well. That experience has honed her coaching skills to a master’s level. Perhaps the most important thing that Fatima brings to the table is an ability to understand not just where you are, but where you have been and where you are hoping to go. She then carefully and THOUGHTFULLY navigates her clients to their best selves. Her deep and caring nature and intense knowledge of the right tools and strategies for every situation is the winning recipe. There is simply nothing Fatima can’t or won’t do to ensure that her clients are successful on every level.
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                </p>
              </blockquote>

              
            </div>
            <div className="mb-8 sm:break-inside-avoid">
              <blockquote className="rounded-xl bg-gray-50 p-6 shadow">
              <div className="mt-4 flex items-center gap-4">
                <img
                  alt="Abbygail P."
                  src={abigal}
                  className="h-12 w-12 rounded-full object-cover"
                />

                <div className="text-sm">
                  <p className="font-medium">Abbygail P. - Founder BLOOM A NEW U</p>
                 
                </div>
              </div> 
                <p className="leading-relaxed text-gray-700">
                Embarking on a journey with my wonderful Coach Fatima has been nothing short of transformative. Initially, my goal was simple: shed a few pounds. But what unfolded was a profound metamorphosis. Through her guidance, I didn't just watch the scale tip—I blossomed in self-esteem and brimmed with newfound confidence. It was as if I had unlocked a hidden strength within me, propelling me toward my desires with the right tools in hand. I'm beyond grateful for this experience; it's reshaped not just my body, but my spirit and future.
                </p>
              </blockquote>

              
            </div>

            <div className="mb-8 sm:break-inside-avoid">
              <blockquote className="rounded-xl bg-gray-50 p-6 shadow">
              <div className="mt-4 flex items-center gap-4">
                <img
                  alt="Zarina S."
                  src={zarina}
                  className="h-12 w-12 rounded-full object-cover"
                />

                <div className="text-sm">
                  <p className="font-medium">Zarina S. - Founder ZarinasDesignStudio</p>
  
                </div>
              </div>
                <p className="leading-relaxed text-gray-700">
                I recently had the pleasure of working with Fatima, a remarkable life coach whose expertise has profoundly impacted both my personal and professional life. Four years into my entrepreneurial journey, I found myself struggling to maintain a healthy balance between my business and personal commitments. The stress was taking its toll, and I knew I needed guidance to navigate this challenging phase. Enter Fatima Sheikh.					
<br></br>
From our very first session, Fatima's approach was nothing short of transformative. With her insightful guidance and unwavering support, she helped me identify key areas where I could improve my time management skills. Her strategies were practical, tailored specifically to my needs, and easy to implement into my daily routine.					
<br></br>One of the most significant benefits of working with her was the decrease in my stress levels. As she helped me streamline my schedule and set realistic goals, I felt a weight lift off my shoulders. Suddenly, the daunting task of balancing my business and personal life seemed much more manageable, thanks to Fatima's expertise.					
<br></br>What sets Fatima apart is not just her knowledge and expertise, but also her remarkable ability to communicate. Her calming demeanor and empathetic approach create a safe space where you feel heard and understood. She doesn't just offer solutions; she empowers you to discover them yourself, fostering a sense of ownership and accountability.				
<br></br>I wholeheartedly recommend Fatima to anyone who finds themselves struggling with the complexities of life. Whether you're grappling with time management, seeking work-life balance, or simply feeling overwhelmed by stress, she has the insight and skills to guide you toward a more fulfilling and harmonious existence. Working with her has been a truly enriching experience, and I am immensely grateful for the positive impact she has had on my life. 

                </p>
              </blockquote>

              
            </div>

          </div>
        </div>
      </section>
    </div>
  );
};

export default Testimonials;
