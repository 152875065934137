import React, { useState, useEffect } from 'react';
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const [sticky, setSticky] = useState(false);
  const navigate = useNavigate();

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`flex justify-between items-center h-24 max-w-[1540px] mx-auto px-4 text-black ${sticky ? 'fixed bottom-0 left-0 right-0 transform translate-y-0' : 'relative'} z-50 bg-white bg-opacity-60 hidden md:flex`}>
      <h1 className="w-full text-3xl font-bold text-[#d9b99b]">
        <a href=""><span style={{ fontSize: '0.7em' }}>life</span><span style={{ fontSize: '1.5em' }}>BALANZ</span><span style={{ fontSize: '0.7em' }}>coach</span></a>
      </h1>

      <ul className="hidden md:flex justify-end gap-6">
        <li className="p-4 text-2xl cursor-pointer">
          <a href="#about">About</a>
        </li>
        <li className="p-4 text-2xl cursor-pointer">
          <a href="#programs">Programs</a>
        </li>
        <li className="p-4 text-2xl cursor-pointer">
          <a href="#cards">Testimonials</a>
        </li>
        <li className="p-4 text-2xl cursor-pointer">
          <button onClick={() => navigate('/form')}>Consultation</button>
        </li>
        <li
          className="p-4 text-2xl relative"
          onMouseEnter={() => {}}
        >
          {/* ... */}
        </li>
      </ul>
    </div>
  );
};

export default Navbar;