import emailjs from 'emailjs-com';
import { useRef } from 'react';
import './Form.css'; // Import the CSS file
import { useNavigate } from 'react-router-dom'; // Import useHistory hook

const Form = () => {
  const nameRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();
  const messageRef = useRef();
  const navigate= useNavigate(); // Create a history object

  const validateForm = (values) => {
    for (let key in values) {
      switch (key) {
        case "name":
        case "email":
          if (values[key] === "") {
            alert(`${key} must not be empty`);
            return false;
          }
          break;
        case "phone":
          if (values[key] === "") {
            alert(`${key} must not be empty`);
            return false;
          } else if (isNaN(values[key]) || values[key].length !== 10) {
            alert(`Please enter a 10 digit number`);
            return false;
          }
          break;
        
        default:
          break;
      }
    }
    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const values = {
      name: nameRef.current.value,
      email: emailRef.current.value,
      phone: phoneRef.current.value,
      message: messageRef.current.value
    };

    if (validateForm(values)) {
      emailjs.send('service_sgehi45', 'template_i2qgn1i', values, 'sFcmJUaseFlWMQs8t')
        .then((result) => {
          console.log(result.text);
          console.log(values);
          alert('Message successfully sent!'); // Show a popup
          navigate('/'); // Redirect to the main page
        }, (error) => {
          console.log(error.text);
        });
    }
  };

  const handleClose = () => {
    navigate('/'); // Redirect to the main page
  };

  return (
    <div className="form-container">
      
      
      
      <form onSubmit={handleSubmit}>
      <h1>For your complimentary consultation, or to ask questions please fill out this form.</h1>
      <button className="close-button" onClick={handleClose}>X</button>
        <input className="form-input" ref={nameRef} type="text" name="name" placeholder="Name" />
        <input className="form-input" ref={emailRef} type="email" name="email" placeholder="Email" />
        <input className="form-input" ref={phoneRef} type="text" name="phone" placeholder="Phone Number" />
        <input className="form-input" ref={messageRef} type="text" name="message" placeholder="Message" />
        <input className="form-submit" type="submit" value="Send" />
      </form>
    </div>
  );
};

export default Form;