
import React from 'react';
import { Navigate, useNavigate } from "react-router-dom";

const Programs = () => {
  const navigate = useNavigate();
  return (
    <div id="programs" className="  " style={{ } }>
      <div
        id="programs"
        className=" font-semi-bold mb-6 md: text-center h-12 text-4xl font-semi-bold font-serif font-thin text-gray-1000"
      >



        Programs
        <div className="ml-auto mr-auto mt-1 w-1/5 border-b border-double border-gray-400"></div>
        
      </div>
      <div  className="w-full bg-white py-12 px-4"  >
      
      <div className="max-w-[1240px] mx-auto grid md:grid-cols-1 flex flex-col justify-center items-center mb-6  "style={{paddingLeft: "0vw"}}>
        
        <div className="flex flex-col justify-center"></div>
  <p className="font-thin text-gray-1000" >
      <p>
      We often define balance as only between family and work. I invite you to consider a third factor, probably the most important, that will set the equilibrium you are so needing, self-care.
      </p>
      <br></br>
      <p>Each lifestyle has unique challenges and demands customized solutions. In the initial complimentary consultation, we will explore the possibilities and devise a specific plan to help achieve your results. My approach focuses on implementing small daily habit changes that will help you reap the most lasting benefits.</p>
      <br></br>
      <p>
      The coaching programs are designed around 90 days for maximum results and easy commitment. Individual and group sessions offered.
      </p>
      <br></br>
      <p ><strong><span style={{fontWeight: 500}}>Life Balanz 90 Day Program </span></strong> is focused on helping you live your best life. By identifying areas in family and work life needing attention, I will help you carve out much needed time for self-care and create sustainable long term habits.
      </p>  
      <br></br>
      <p><strong><span style={{fontWeight: 500}}>Women Returning to Work 90 Day Program </span></strong> is focused on helping ease the transition back to the work force whether it's right after maternity leave, or several years later. I will help you restructure your schedule and create sustainable long term habits to make sure you are relaxed and prepared for the job force while balancing family and self-care.</p>
      <br></br>
      <p><strong><span style={{fontWeight: 500}}>Reduce Stress 90 Day Program </span></strong> is designed to bring peace, calm and clarity back into your life. By addressing stressors and implementing daily habit changes to manages stress, I will help you improve your quality of life.</p>
      <p>
        <br></br>
        All programs include the following: 
        <ul style={{ paddingLeft: '1.5em' }}>
          <li>• A complimentary 30 minute consultation</li>
          <li>• 12 private and confidential sessions</li>
          <li>• Each session is 45 minutes live on-line video calls.</li>
          <li>• Text and email access</li>
        </ul>

        
      </p>
      <br></br>
        <p>Where in your life do you need balance?</p>
   
      <br></br>
      <h2 style={{  marginBottom: '-0.8em' }}className=" font-semi-bold mb-6 md: text-left h-12 text-2xl font-semi-bold font-serif font-thin text-gray-1000">Family/Relationships</h2>
      <p>
      A priority in our lives, whether we have our own family or are looking to find new relationships.
Are you struggling to spend quality time? Trying to manage commitments? Setting boundaries difficult? Or perhaps you are looking to find new relationships? 

      </p>
      <br></br>
      <h2 style={{  marginBottom: '-0.8em' }}className=" font-semi-bold mb-6 md: text-left h-12 text-2xl font-semi-bold font-serif font-thin text-gray-1000">Work</h2>
      <p>
      A necessity for our sustenance and livelihood, the time dedicated to work can overshadow family obligations and eliminate any possibility of self-care. Setting boundaries, communicating expectations, and simply saying no, are a few of the issues needing to be addressed.
      </p>
      <br></br>
      <h2 style={{  marginBottom: '-0.8em' }}className=" font-semi-bold mb-6 md: text-left h-12 text-2xl font-semi-bold font-serif font-thin text-gray-1000">Self-care</h2>
      <p>
      Nurturing ourselves is a priority and a necessity, however, most of us treat it like a luxury. Time, divided between family and work, leaves us depleted and having no energy for self-care. The misconception is pampering ourselves will  take hours. Let’s explore and find time in your daily schedule for your self-care.
      </p>
      <br></br>
      <p>Let’s connect and I can help you learn how to recharge, re-define and be the best version of yourself.</p>
      <br></br>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
  <button
    onClick={() => {
      navigate("./form");
    }}
    className="bg-gray-600 text-[#d9b99b] w-full sm:w-1/3 md:w-1/4 lg:w-1/5 xl:w-1/6 rounded-md font-medium py-3 justify-center hover:bg-gray-900 transition duration-200 ease-in-out"
    style={{ marginTop: "10px", padding: "10px 20px", fontSize: "18px" }}
  >
    Let's get started
  </button>
</div>
      {/* <h2 style={{  marginBottom: '-0.8em' }}className=" font-semi-bold mb-6 md: text-left h-12 text-2xl font-semi-bold font-serif font-thin text-gray-400">Purpose</h2>
      <p>
        Maybe you are exploring something that has meaning for you and want to pursue a career or lifestyle that you value.
      </p>
      <br></br>
      <h2 style={{  marginBottom: '-0.8em' }}className="  font-semi-bold mb-6 md: text-left h-12 text-2xl font-semi-bold font-serif font-thin text-gray-400">Relationships</h2>
      <p>
        Ready to invest in a serious commitment or simply explore the options out there? Let’s devise a strategy so you can be in the relationship you envision.
      </p>
      <br></br>
      <h2 style={{  marginBottom: '-0.8em' }}className="  font-semi-bold mb-6 md: text-left h-12 text-2xl font-semi-bold font-serif font-thin text-gray-400">Money</h2>
      <p>
        What’s holding you back to earning more? Maybe you feel overwhelmed and need a reset, or are ready to take your earnings to the next step.
      </p> */}
      </p>
    </div>
   
    </div>
    </div>


  );
};

export default Programs;