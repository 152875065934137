import React from "react";
import {
  FaDribbbleSquare,
  FaFacebookSquare,
  FaGithubSquare,
  FaInstagram,
  FaTwitterSquare,
} from "react-icons/fa";

const Footer = () => {
  return (
    <div className="max-w-[1240px] mx-auto py-16 px-4 grid lg:grid-cols-3 gap-8 text-gray-300">
      <div>
        <h1 className="w-full text-4xl font-bold text-[#d9b99b]">
        <div className='w-full  font-bold text-[#d9b99b]'>
          <a href=""><span style={{ fontSize: '0.7em' }}>life</span><span style={{ fontSize: '1.5em' }}>BALANZ</span><span style={{ fontSize: '0.7em' }}>coach</span></a>
          </div>
        </h1>
        <p className="py-4">© 2024 All rights reserved </p>
        <div className="flex justify-between md:w-[75%] my-6">
          <FaFacebookSquare size={30} />
          <FaInstagram size={30} />
          <FaTwitterSquare size={30} />
          <FaGithubSquare size={30} />
          <FaDribbbleSquare size={30} />
        </div>
      </div>
    </div>
  );
};

export default Footer;
